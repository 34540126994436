import Template from './template';
import Config from '../config';
import AuthConfig from '../auth-config';

function System() {
    return <Template selectedNavItem="system">
        <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">System Information</h1>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Configuration Parameters</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive" style={{overflow: 'hidden'}}>
                                <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6"></div>
                                        <div className="col-sm-12 col-md-6"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <table className="table table-bordered dataTable no-footer" id="dataTable"
                                                   width="100%" cellSpacing="0" aria-describedby="dataTable_info"
                                                   style={{width: '100%'}}>
                                                <thead>
                                                <tr role="row">
                                                    <th className="sorting sorting_asc" tabIndex="0"
                                                        aria-controls="dataTable" rowSpan="1" colSpan="1"
                                                        aria-sort="ascending"
                                                        aria-label="Parameter Name: activate to sort column descending"
                                                        style={{width: '527.667px'}}>Parameter Name
                                                    </th>
                                                    <th className="sorting" tabIndex="0" aria-controls="dataTable"
                                                        rowSpan="1" colSpan="1"
                                                        aria-label="Parameter Value: activate to sort column ascending"
                                                        style={{width: '1111.67px'}}>Parameter Value
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    <tr role="row">
                                                        <td className="sorting_1">API URL</td>
                                                        <td>{Config.getApiURL()}</td>
                                                    </tr>
                                                    <tr role="row">
                                                        <td className="sorting_1">OAuth Tenant ID</td>
                                                        <td>{AuthConfig.getTenantId()}</td>
                                                    </tr>
                                                    <tr role="row">
                                                        <td className="sorting_1">OAuth Client ID</td>
                                                        <td>{AuthConfig.getClientId()}</td>
                                                    </tr>
                                                    <tr role="row">
                                                        <td className="sorting_1">IO URL</td>
                                                        <td>{Config.getIoUrl()}</td>
                                                    </tr>
                                                    <tr role="row">
                                                        <td className="sorting_1">Data Server URL</td>
                                                        <td>{Config.getDataServerUrl()}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Template>;
}
export default System;

import {InteractionRequiredAuthError} from "@azure/msal-browser";
import AuthConfig from "./auth-config";
import Config from './config';
import { jwtDecode } from "jwt-decode";
import {toast} from "react-toastify";

class ApiClient {

    #isTokenExpired(expiration) {
        let currentUnixEpochTimeInSeconds = new Date().getTime() / 1000;
        let gracePeriodInSeconds = 3 * 60;
        if (currentUnixEpochTimeInSeconds + gracePeriodInSeconds > expiration) {
            return true;
        }
        return false;
    }

    #isTokenValid(token) {
        if (!token) {
            return false;
        }
        let decodedToken;
        try {
            decodedToken = jwtDecode(token);
        } catch (ex) {
            return false;
        }
        if (this.#isTokenExpired(decodedToken.exp)) {
            return false;
        }
        return true;
    }

    async #getValidTokenFromCache() {
        const publicClientApplication = await AuthConfig.getPublicClientApplication();
        let account = publicClientApplication.getAllAccounts()[0];
        let tokenItemKey = `${account.homeAccountId}-login.microsoftonline.com-accesstoken-${AuthConfig.getClientId()}-${AuthConfig.getTenantId()}-${AuthConfig.getScope()}--`;
        let tokenFromCache = localStorage.getItem(tokenItemKey);

        try {
            let parsedToken = JSON.parse(tokenFromCache);
            if (this.#isTokenValid(parsedToken.secret)) {
                return parsedToken.secret;
            }
        } catch (ex) {
            console.log(`Unknown id token format found in local storage ${tokenFromCache}. Exception ${ex}`);
        }

        return null;
    }

    async #acquireTokenInteractive(interactiveLoginType, accessTokenRequest) {
        const publicClientApplication = await AuthConfig.getPublicClientApplication();
        if (interactiveLoginType === 'redirect') {
            await publicClientApplication.acquireTokenRedirect(accessTokenRequest);
        }
        let accessTokenResponse = await publicClientApplication.acquireTokenPopup(accessTokenRequest)
        return accessTokenResponse.accessToken;
    }

    /*
        interactiveLoginType: 'redirect' or 'popup'. defaults to 'popup'. token will not be returned if interactive login type is redirect and an interactive login is required.

        Get a token using the following fallback logic:
        1. Get a token from local storage.
            a. Avoids a request to openid-configuration on each page load when valid tokens exist.
                The data from openid-configuration is only cached in memory see here: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/3607
            b. Eliminates the need to call acquireTokenSilent with and without the forceRefresh flag, because without forceRefresh acquireTokenSilent may return expired id tokens.
                This is by design see here: https://github.com/AzureAD/microsoft-authentication-library-for-dotnet/issues/3119
        2. Acquire token silently using the refresh token in local storage in a pop-up.
            a. Refresh tokens expire after 24 hours and this can't be changed.
        3. Require the user to enter their username and password in a pop-up.
     */
    async getToken(interactiveLoginType) {
        const publicClientApplication = await AuthConfig.getPublicClientApplication();
        let account = publicClientApplication.getAllAccounts()[0];
        const accessTokenRequest = {
            scopes: [`api://${AuthConfig.getClientId()}/mxs.default`], // At least one scope is required for caching to work see https://stackoverflow.com/questions/67327004/msal-browser-with-msal-react-wrapper-acquiretokensilent-doesnt-get-access-token and https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2451
            account: account,
            forceRefresh: true // We know the tokens are stale at this point so always force refresh
        };
        if (!account) {
            return await this.#acquireTokenInteractive(interactiveLoginType, accessTokenRequest);
        }

        let validTokenFromCache = await this.#getValidTokenFromCache();
        if (validTokenFromCache) {
            return validTokenFromCache;
        }

        try {
            let accessTokenResponse = await publicClientApplication.acquireTokenSilent(accessTokenRequest);
            return accessTokenResponse.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                return await this.#acquireTokenInteractive(interactiveLoginType, accessTokenRequest);
            } else {
                throw error;
            }
        }
    }

    getJsonExceptionText(error) {
        let jsonErrorResponse;
        try {
            jsonErrorResponse = JSON.parse(error.response);
        } catch (exception) {
            return '';
        }

        if (jsonErrorResponse.error && typeof jsonErrorResponse.error === 'object') {
            return JSON.stringify(jsonErrorResponse.error, 0, 4);
        }

        return jsonErrorResponse.error;
    }

    async fetch(path, method, dataOut, toastSettings) {
        let toastId;
        if (toastSettings) {
            toastId = toast.loading( toastSettings.loadingMessage || 'loading...');    
        }
        let url = `${Config.getApiURL()}${path}`;
        try {
            let token = await this.getToken();
            let params = {
                'method': method,
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            };
            if (dataOut) {
                params.body = JSON.stringify(dataOut);
            }
            let response = await fetch(url, params);
            if (response.status.toString() === '401') {
                let errorResponse = await response.text(); // Don't authenticate again. If getToken() didn't produce a valid credential attempting to authenticate again will only result in a redirect loop.
                throw { // eslint-disable-line no-throw-literal
                    customError: `You aren't unauthorized for the endpoint ${method} ${url}. Ask your administrator for access. If your administrator recently granted you access, clear your localStorage and cookies then try again in a new window.`,
                    status: response.status,
                    url: response.url,
                    response: errorResponse
                };
            }
            if (response.status.toString()[0] !== '2') {
                let errorResponse = await response.text();
                throw { // eslint-disable-line no-throw-literal
                    status: response.status,
                    url: response.url,
                    response: errorResponse
                };
            }
            let textResponse = await response.text();
            if (toastId) {
                toast.update(toastId, { render: toastSettings.successMessage, type: "success", isLoading: false, autoClose: true, closeButton: true });    
            }
            if (!textResponse) {
                return textResponse; // DELETE's should return 204 with an empty body
            }
            return JSON.parse(textResponse);
        } catch (error) {
            let errorDescription;
            let jsonError = this.getJsonExceptionText(error);
            if (jsonError) {
                errorDescription = `An error occurred when calling the endpoint ${method} ${url} - ${jsonError}`;
            }
            else if (error.customError) {
                errorDescription = error.customError;
            } else if (error.name === 'InteractionRequiredAuthError') {
                errorDescription = `Your session has expired. Login in the popup.`;
            } else {
                let errorText = Object.keys(error).length > 0 ? JSON.stringify(error, 0, 4) : error;
                errorDescription = `An error occurred when calling the endpoint ${method} ${url} - ${errorText}`;
            }
            console.log(errorDescription);
            if (toastId) {
                toast.update(toastId, {render: errorDescription, type: "error", isLoading: false, autoClose: false, closeButton: true });    
            }
            throw errorDescription;
        }
    }
}

export default ApiClient;
import Config from "../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import CurrencyDefaults from "../currency-defaults";
import React from "react";

const IOLink = ({ ioId, columnSelector, ioIndex, delimeter }) => (
    <span key={`${columnSelector}-${ioId}-${ioIndex}`}>
    <a target="_blank" rel="noreferrer" className="io-link clickable" href={`${Config.getIoUrl()}/io/view/${ioId}/`}>
      IO: {ioId}
    </a>
        {delimeter}
        <FontAwesomeIcon icon={faExternalLinkAlt} className="fa-sm fa-fw ml-2" />
  </span>
);

const CampaignGroupCell = ({
   campaignGroupMonth,
   showIo,
   hasWrite,
   showIoAmount,
   showPlannedAmount,
   showMargin,
   showInvoiceAmount,
   showInvoiceNumber,
   columnSelector,
   setTransferModal,
   row,
   selectedYear,
   campaignQueryData,
   showMonthModal,
   month,
   showFlightDate}) => {

    const handleClickForTransferModal = () => {
        if (!hasWrite) {
            return;
        }
        if (!campaignGroupMonth) {
            return;
        }
        let campaigns = campaignQueryData
            .filter(x => x.company.id === row.company.id)
            .filter(x => x.platform.id === row.platform.id)
            .map(x => ({ label: x.campaign.name, value: x.campaign.id }));
        let defaultCampaignOption = campaigns.find(option => option.value === row.campaign.id);
        const monthOptions = Config.monthNames.map((name, index) => ({
            value: index + 1,
            label: name
        }));
        const currentMonthOption = monthOptions.find(option => option.value === month);
        const yearOptions = [
            { value: 2023, label: "2023" },
            { value: 2024, label: "2024" }
        ];
        const currentYearOption = yearOptions.find(option => option.value === selectedYear);
        setTransferModal({
            visible: true,
            campaignGroupMonth,
            company: row.company,
            campaign: row.campaign,
            platform: row.platform,
            year: selectedYear,
            month,
            ioAmount: campaignGroupMonth ? campaignGroupMonth.ioAmount : null,
            internalExpenseAmount: campaignGroupMonth ? CurrencyDefaults.round(campaignGroupMonth.plannedInvoiceAmount, 4) : null,
            campaigns,
            receivingCampaign: defaultCampaignOption,
            monthOptions: monthOptions,
            receivingMonth: currentMonthOption,
            saving: false,
            receivingYear: currentYearOption,
            yearOptions: yearOptions
        });
    };

    const getFunding = () => {
        if (!campaignGroupMonth) {
            return 'N/A';
        }
        return Config.getFundingDescription(campaignGroupMonth.funding);
    };
    return (
        <div className="campaign-group-cell">
            { showFlightDate && campaignGroupMonth?.flightStartDate != null && campaignGroupMonth?.flightEndDate && <div>{campaignGroupMonth?.flightStartDate} - {campaignGroupMonth?.flightEndDate}</div>}
            { campaignGroupMonth && showIo && hasWrite && campaignGroupMonth.ioLineItems && campaignGroupMonth.ioLineItems.length > 0 &&
                <div className="io-links-container">
                    {
                        campaignGroupMonth.ioLineItems
                            .filter(x => (x.iosOrderIds || []).length > 0) // Exclude transfers
                            .map((x) => {
                                    return x.iosOrderIds.map((ioId, ioIndex, array) => {
                                        return <IOLink
                                            key={`${columnSelector}-${ioId}-${ioIndex}`}
                                            ioId={ioId}
                                            columnSelector={columnSelector}
                                            ioIndex={ioIndex}
                                            delimeter={ioIndex < array.length - 1 ? ', ' : ''}
                                        />;
                                        }
                                    );
                                }
                            )
                    }
                </div>
            }
            { showIoAmount && hasWrite &&
                <div>Gross: {campaignGroupMonth ? CurrencyDefaults.format(campaignGroupMonth.ioAmount) : CurrencyDefaults.format(0)}</div> }
            { <div>Funding: {getFunding()}</div> }
            { showPlannedAmount &&
                <div
                    className={campaignGroupMonth && hasWrite ? 'clickable' : ''}
                    onClick={handleClickForTransferModal}>
                    <div>Planned: {campaignGroupMonth ? CurrencyDefaults.format(campaignGroupMonth.plannedInvoiceAmount) : CurrencyDefaults.format(0)}</div>
                </div>
            }
            { showMargin && hasWrite &&
                <div className={hasWrite ? 'clickable' : ''}
                     onClick={() => showMonthModal(campaignGroupMonth, row.company, row.campaign, row.platform, month)}>
                    Margin:&nbsp;
                    {
                        !campaignGroupMonth || !campaignGroupMonth.ioLineItems || campaignGroupMonth.ioLineItems.length < 1
                            ? '0'
                            : Array.from(new Set(campaignGroupMonth.ioLineItems.map(x => x.margin))).map((margin, index, array) => {
                                return <span key={`${columnSelector}-${index}-margin`}>{margin}{ index < array.length - 1 ? ', ' : '' }</span>
                            })
                    }
                </div>
            }
            { showInvoiceAmount &&
                <div className={hasWrite ? 'clickable' : ''}
                     onClick={() => showMonthModal(campaignGroupMonth, row.company, row.campaign, row.platform, month)}>
                    Invoiced:&nbsp;
                    {campaignGroupMonth && campaignGroupMonth.actualExpense && campaignGroupMonth.actualExpense.invoiceAmount
                        ? CurrencyDefaults.format(campaignGroupMonth.actualExpense.invoiceAmount)
                        : CurrencyDefaults.format(0)}
                </div>
            }
            { showInvoiceNumber &&
                <div className={hasWrite ? 'clickable' : ''}
                     onClick={() => showMonthModal(campaignGroupMonth, row.company, row.campaign, row.platform, month)}>
                    Invoice Number:&nbsp;
                    {campaignGroupMonth && campaignGroupMonth.actualExpense && campaignGroupMonth.actualExpense.invoiceNumber ? campaignGroupMonth.actualExpense.invoiceNumber : ''}
                </div>
            }
        </div>
    );
};

export default CampaignGroupCell;

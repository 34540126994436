import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import CurrencyDefaults from '../currency-defaults';
import ReactSelect from 'react-select';
import ApiClient from '../api-client';
import Template from './template';
import '../css/floating-labels.css';
import '../css/media-expenses.css';
import '../css/pacing.css';
import 'react-tooltip/dist/react-tooltip.css'
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import calculator from "../calculator.js";

const now = new Date();
const nowDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
const nowYear = nowDate.getFullYear();
const nowMonth = nowDate.getMonth();

function Pacing(props) {
    
    const [companySelectValue, setCompanySelectValue] = useState('');
    const [companySelectData, setCompanySelectData] = useState([]);
    const [campaignSelectValue, setCampaignSelectValue] = useState('');
    const [campaignSelectData, setCampaignSelectData] = useState([]);
    const [platformSelectValue, setPlatformSelectValue] = useState([]);
    const [platformSelectData, setPlatformSelectData] = useState([]);

    const [selectedYear, setSelectedYear] = useState(2024);
    const [selectedMonth, setSelectedMonth] = useState(nowMonth);
    const [years, setYears] = useState([]);
    const [campaignQueryData, setCampaignQueryData] = useState([]);
    const [campaignTableData, setCampaignTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    
    const monthOptions = Array.from({ length: 12 }, (_, index) => {
      const date = new Date(nowYear, index, 1);
      return {
        value: index,
        label: date.toLocaleString('default', { month: 'long' })
      };
    });

    const columns = [
        {
            name: 'Campaign ID',
            selector: row => `${row.company.id}.${row.campaign.id}.${row.platform.id}`,
            sortable: true,
            width: '130px'
        },
        {
            name: 'Company',
            selector: row => row.company.name,
            sortable: true
        },
        {
            name: 'Campaign',
            selector: row => row.campaign.name,
            sortable: true
        },
        {
            name: 'Platform',
            selector: row => row.platform.name,
            sortable: true
        },
        {
            name: 'Start Date',
            selector: row => row.months[0].flightStartDate,
            width: '110px',
            sortable: true
        },
        {
            name: 'End Date',
            selector: row => row.months[0].flightEndDate,
            width: '100px',
            sortable: true
        },
        {
            name: 'Planned',
            selector: row => row.months[0].plannedInvoiceAmount,
            cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.months[0].plannedInvoiceAmount)}</div>,
            width: '110px',
            sortable: true
        },
        {
            name: 'Invoiced',
            selector: row => row.months[0].actualExpense ? row.months[0].actualExpense.invoiceAmount : 0,
            cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.months[0].actualExpense ? row.months[0].actualExpense.invoiceAmount : 0)}</div>,
            width: '110px',
            sortable: true
        },
        {
            name: 'Balance',
            selector: row => row.pacing.balance,
            cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.pacing.balance)}</div>,
            width: '110px',
            sortable: true
        },
        {
            name: 'Target Daily Budget',
            selector: row => row.pacing.targetDailyBudget,
            cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.pacing.targetDailyBudget)}</div>,
            width: '110px',
            sortable: true
        },
        {
            name: 'Avg. Daily Budget',
            selector: row => row['pacing.averageDailyBudget'],
            cell: row => <div className="right-align-column">{CurrencyDefaults.formatFixed(row.pacing.averageDailyBudget)}</div>,
            width: '110px',
            sortable: true
        },
        {
            name: '% to Budget through yesterday',
            selector: row => row.pacing.spendingAdjustmentPercentage,
            cell: row => <div className="right-align-column">{
                CurrencyDefaults.formatPercentage(CurrencyDefaults.round(row.pacing.spendingAdjustmentPercentage, 4))
            }</div>,
            width: '110px',
            sortable: true
        },
        {
            name: 'Days Elapsed',
            selector: row => row.pacing.daysElapsed,
            cell: row => <div className="right-align-column">{row.pacing.daysElapsed}</div>,
            width: '100px',
            sortable: true
        },
        {
            name: 'Days Remaining',
            selector: row => row.pacing.daysRemaining,
            cell: row => <div className="right-align-column">{row.pacing.daysRemaining}</div>,
            width: '100px',
            sortable: true
        }
    ];

    useEffect(() => {
        setDataAndApplyFilters(campaignQueryData);
    }, [companySelectValue, campaignSelectValue, platformSelectValue, selectedMonth]);

    useEffect(() => {
        async function fetchData() {
            try {
                setTableLoading(true);
                let data = await new ApiClient().fetch(`/Campaign/group/${selectedYear}/${selectedMonth + 1}`, 'GET');
                setCampaignQueryData(data);
                setTableLoading(false);
                clearSearch();
                setDataAndApplyFilters(data);
            } catch (error) {
                toast.error(error, { autoClose: false});
            }
        }
        fetchData();
    }, [selectedYear, selectedMonth]);
    
    useEffect(() => {
        async function fetchData() {
            try {
                let yearsResponse = await  new ApiClient().fetch('/Campaign/years', 'GET', null, false);
                let yearToBeSelected;
                if (yearsResponse.find(x => x === nowYear)) {
                    yearToBeSelected = nowYear;
                } else {
                    yearToBeSelected = yearsResponse[0];
                }
                setSelectedYear(yearToBeSelected);
                setYears(yearsResponse);
            } catch (error) {
                toast.error(error, { autoClose: false});
            }
        }
        fetchData();
    },[]);

    function setFilters(data) {
        const uniqueCompanies = Array.from(new Set(data.map(x => x.company.name)));
        uniqueCompanies.sort();
        setCompanySelectData(uniqueCompanies.map(x => ({ label: x, value: x })));
        const uniqueCampaigns = Array.from(new Set(data.map(x => x.campaign.name)));
        uniqueCampaigns.sort();
        setCampaignSelectData(uniqueCampaigns.map(x => ({ label: x, value: x })))
        const uniquePlatformIds = Array.from(new Set(data.map(x => x.platform.id)));
        const uniquePlatforms = Array.from(uniquePlatformIds.map(x => data.find(y => x === y.platform.id).platform));
        uniquePlatforms.sort((a, b) => (getPlatformText(a) > getPlatformText(b)) ? 1 : -1);
        setPlatformSelectData(uniquePlatforms.map(x => ({ label: getPlatformText(x), value: x.id })));
    }

    function setDataAndApplyFilters(data) {
        let filteredData = data.reduce((acc, campaignGroup) => {
            const companyMatch = !companySelectValue || campaignGroup.company.name === companySelectValue;
            const campaignMatch = !campaignSelectValue || campaignGroup.campaign.name === campaignSelectValue;
            const platformMatch = !platformSelectValue.length || getPlatformText(campaignGroup.platform) === platformSelectValue;
            if (companyMatch && campaignMatch && platformMatch) {
                acc.push(campaignGroup);
            }
            return acc;
        }, []);
        setFilters(filteredData);
        
        filteredData = filteredData.filter(x =>
            x.months &&
            x.months.length > 0 &&
            (
                 x.months[0].plannedInvoiceAmount > 0 ||
                 x.months[0].actualExpense?.inputInvoiceAmount > 0
            )
        );
        
        const currentLocalDate = new Date();
        const currentDateEpochTime = Date.UTC(currentLocalDate.getUTCFullYear(), currentLocalDate.getUTCMonth(), currentLocalDate.getUTCDate());
        const modifiedDat = filteredData.map(item => {
            item.uuid = uuidv4();
            let pacing = calculator.getBudgetPacingInfo(
                new Date(item.months[0].flightStartDate).getTime(),
                new Date(item.months[0].flightEndDate).getTime(),
                currentDateEpochTime,
                CurrencyDefaults.factory(item.months[0].plannedInvoiceAmount),
                CurrencyDefaults.factory(item.months[0]?.actualExpense?.invoiceAmount ?? 0)
            );
            item.pacing = pacing;
            return item;
        });
        setCampaignTableData(modifiedDat);
    }

    function clearSearch() {
        setCompanySelectValue('');
        setCampaignSelectValue('');
        setPlatformSelectValue('');
    }

    function getPlatformText(platform) {
        if (!platform) {
            return 'Any';
        }
        let platformName = (platform.description || '').toLowerCase() === (platform.name || '').toLowerCase()
            ? platform.description
            : `${platform.description} - ${platform.name}`;
        return platformName;
    }

    return <Template selectedNavItem="media-expenses">
        <div className="container-fluid media-expense-screen">
            <div className="row">
                <div className="col-xl-2">
                    <ReactSelect
                        className="react-select"
                        options={years.map(x => ({ label: x, value: x }))}
                        isSearchable={false}
                        isClearable={false}
                        placeholder="Year"
                        defaultValue={{ label: selectedYear, value: selectedYear }}
                        onChange={(option) => setSelectedYear(option ? option.label : '')}
                    />
                </div>
                <div className="col-xl-2">
                    <ReactSelect
                        className="react-select"
                        options={monthOptions}
                        isSearchable={false}
                        isClearable={false}
                        placeholder="Month"
                        defaultValue={monthOptions.find(x => x.value === nowMonth)}
                        onChange={(option) => setSelectedMonth(option ? option.value : '')}
                    />
                </div>
                <div className="col-xl-2">
                    <ReactSelect
                        className="react-select"
                        options={companySelectData}
                        isSearchable={true}
                        isClearable={true}
                        placeholder="Company"
                        onChange={(option) => setCompanySelectValue(option ? option.label : '')}
                    />
                </div>
                <div className="col-xl-2">
                    <ReactSelect
                        className="react-select"
                        options={campaignSelectData}
                        isSearchable={true}
                        isClearable={true}
                        placeholder="Campaign"
                        onChange={(option) => setCampaignSelectValue(option ? option.label : '')}
                    />
                </div>
                <div className="col-xl-2">
                    <ReactSelect
                        className="react-select"
                        options={platformSelectData}
                        isSearchable={true}
                        isClearable={true}
                        placeholder="Platform"
                        onChange={(option) => setPlatformSelectValue(option ? option.label : '')}
                    />
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-xl-12">
                    <DataTable
                        columns={columns}
                        data={campaignTableData}
                        striped={true}
                        highlightOnHover={true}
                        dense={true}
                        pagination
                        paginationPerPage={20}
                        paginationRowsPerPageOptions={[20, 50, 100]}
                        paginationComponentOptions={{
                            selectAllRowsItem: true
                        }}
                        progressPending={tableLoading}
                        responsive={true}
                        keyField="uuid"
                        customStyles={{
                          cells: {
                            style: {
                              padding: '5px'
                            },
                          },
                          headCells: {
                            style: {
                              paddingLeft: '5px',
                              paddingRight: '5px',
                              '& div': {
                                overflow: 'visible',
                                whiteSpace: 'normal',
                                width: '100%',
                                textAlign: 'center'
                              },
                            },
                          }
                        }}
                    />
                </div>
            </div>
        </div>
    </Template>;
}

export default Pacing;

class Config {

    static getApiURL() {
        if (this.inProduction()) {
            return 'https://api.mxs.datsvr.com';
        }
        if (this.inQa()) {
            return 'https://api.mxs.datsvr.qatserver.com';
        }
        return 'https://localhost:5001';
    }

    static getIoUrl() {
        if (this.inProduction()) {
            return 'https://ios.datsvr.com'
        }
        return 'https://ios.datsvr.qatserver.com';
    }

    static getDataServerUrl() {
        if (this.inProduction()) {
            return 'https://secure.datsvr.com';
        }
        return 'https://secure.datsvr.qatserver.com';
    }

    static inLocal() {
        return window.location.host === 'localhost:3000';
    }

    static inQa() {
        return window.location.host === 'mxs.datsvr.qatserver.com';
    }

    static inProduction() {
        return window.location.host === 'mxs.datsvr.com';
    }

    static monthNames = Array.from({ length: 12 }, (_, monthIndex) =>
        new Date(2024, monthIndex).toLocaleString('default', { month: 'long' })
    );
    
    static getFundingDescription(funding) {
        if (funding === null || funding === undefined) {
            return "N/A";
        }
        if (funding === 0) {
            return "RW"
        }
        return "Client";
    }

}

export default Config;

class UserSettings {
    
    static getUserSettings() {
        let userSettings = localStorage.getItem('userSettings');
        if (!userSettings) {
            userSettings = JSON.stringify({});
        }
        try {
            let userSettingsParsed = JSON.parse(userSettings);
            userSettingsParsed.visibleColumnIndexes = userSettingsParsed.visibleColumnIndexes || [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];
            userSettingsParsed.showFlightDate = userSettingsParsed.showFlightDate === undefined ? true : userSettingsParsed.showFlightDate;
            userSettingsParsed.showIo = userSettingsParsed.showIo === undefined ? true : userSettingsParsed.showIo;
            userSettingsParsed.showIoAmount = userSettingsParsed.showIoAmount === undefined ? true : userSettingsParsed.showIoAmount;
            userSettingsParsed.showPlannedAmount = userSettingsParsed.showPlannedAmount === undefined ? true : userSettingsParsed.showPlannedAmount;
            userSettingsParsed.showMargin = userSettingsParsed.showMargin === undefined ? true : userSettingsParsed.showMargin;
            userSettingsParsed.showInvoiceAmount = userSettingsParsed.showInvoiceAmount === undefined ? true : userSettingsParsed.showInvoiceAmount;
            userSettingsParsed.showInvoiceNumber = userSettingsParsed.showInvoiceNumber === undefined ? true : userSettingsParsed.showInvoiceNumber;
            return userSettingsParsed;
        } catch (error) {
            console.log('Can\'t parse userSettings in local storage. Deleting local storage and rebuilding userSettings with default values');
            console.log(error);
            localStorage.removeItem('userSettings');
            return this.getUserSettings();
        }
    }
    
}

export default UserSettings;
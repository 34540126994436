import ReactSelect from "react-select";
import CurrencyDefaults from "../currency-defaults";
import Calculator from '../calculator';

function MoveExpenseModal(props) {

    return <div className={'transfer-modal'}>
        {
            props.model.visible &&
            <div>
                <div className="modal fade show" id="logoutModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     style={{display: 'block'}} aria-modal="true" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Media Expense Transfer
                                </h5>
                                <div style={{cursor: 'default'}}>
                                    <button
                                        onClick={props.cancel}
                                        className="close" type="button" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div>
                                    {
                                        props.model.errorMessage &&
                                        <div className="col-xl-12">
                                            <div className={`alert alert-dismissible alert-danger`} role="alert">
                                                {props.model.errorMessage}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div>
                                    <strong>Company</strong>
                                    <div>{props.model.company.name}</div>
                                </div>
                                <div>
                                    <strong>Campaign</strong>
                                    <div>{props.model.campaign.name}</div>
                                </div>
                                <div>
                                    <strong>Month</strong>
                                    <div>{new Date(props.model.year, props.model.month - 1).toLocaleString('default', {month: 'long'})}</div>
                                </div>
                                <div>
                                    <strong>Platform</strong>
                                    <div>{props.model.platform.name}</div>
                                </div>
                                <div>
                                    <strong>Gross</strong>
                                    <div>{CurrencyDefaults.format(props.model.campaignGroupMonth.ioAmount)}</div>
                                </div>
                                <div>
                                    <strong>Planned</strong>
                                    <div>{CurrencyDefaults.format(props.model.campaignGroupMonth.plannedInvoiceAmount)}</div>
                                </div>
                                <div>
                                    <strong>Planned Amount to Transfer &nbsp;</strong>
                                    <input type="text"
                                           className="form-control"
                                           value={props.model.internalExpenseAmount || ''}
                                           onChange={e => props.updateModel({
                                               ...props.model,
                                               margin: e.target.value,
                                               internalExpenseAmount: e.target.value,
                                               ioAmount: CurrencyDefaults.round(Calculator.calculateIOAmount(e.target.value, Calculator.getMargin(props.model.campaignGroupMonth.ioLineItems)), 2) // IO amounts can't be beyond a penny precision.
                                           })}
                                    />
                                </div>
                                <div>
                                    <strong>Gross Amount to Transfer &nbsp;</strong>
                                    <input type="text"
                                           className="form-control"
                                           value={CurrencyDefaults.format(props.model.ioAmount)} 
                                           readOnly
                                    />
                                </div>
                                <div>
                                    <strong>Transfer To Campaign&nbsp;</strong>
                                    <ReactSelect
                                        className="react-select"
                                        options={props.model.campaigns}
                                        isSearchable={true}
                                        defaultValue={props.model.receivingCampaign}
                                        onChange={(option) => {
                                            props.model.receivingCampaign = props.model.campaigns.find(x => x.value === option.value );
                                        }}
                                    />
                                </div>
                                <div>
                                    <strong>Transfer To Month&nbsp;</strong>
                                    <ReactSelect
                                        className="react-select"
                                        options={props.model.monthOptions}
                                        isSearchable={true}
                                        defaultValue={props.model.receivingMonth}
                                        onChange={(option) => {
                                            props.model.receivingMonth = props.model.monthOptions.find(x => x.value === option.value );
                                        }}
                                    />
                                </div>
                                <div>
                                    <strong>Transfer To Year&nbsp;</strong>
                                    <ReactSelect
                                        className="react-select"
                                        options={props.model.yearOptions}
                                        isSearchable={true}
                                        defaultValue={props.model.receivingYear}
                                        onChange={(option) => {
                                            props.model.receivingYear = props.model.yearOptions.find(x => x.value === option.value );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button onClick={props.cancel}
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                        disabled={props.model.saving}>Cancel</button>
                                <button className="btn btn-primary"
                                        onClick={props.save}
                                        disabled={props.model.saving}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
            </div>
        }
    </div>
}
export default MoveExpenseModal;
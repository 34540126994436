import {PublicClientApplication} from '@azure/msal-browser';
import Config from './config';

class AuthConfig {

    static #publicClientApplication;
    
    static isAuthenticatedPath() {
        return window.location.pathname !== '/authentication' && window.location.pathname !== '/logout';
    }

    static async getPublicClientApplication() {
        if (!this.#publicClientApplication) {
            this.#publicClientApplication = new PublicClientApplication(this.#getConfig());
            await this.#publicClientApplication.initialize();
        }
        return this.#publicClientApplication;
    }

    static getClientId() {
        if (Config.inProduction()) {
            return '4c58d933-f278-4506-958c-8776c7843e67';
        }
        return '2e572e70-080b-42a0-bcac-3f298ab4ee48';
    }

    static getTenantId() {
        return 'c34279b7-82bd-453a-9788-a67de07fa821';
    }

    static getScope() {
        return `api://${AuthConfig.getClientId()}/mxs.default`;
    }

    static #getConfig() {
        return {
            auth: {
                clientId: this.getClientId(),
                authority: `https://login.microsoftonline.com/${this.getTenantId()}`,
                knownAuthorities: [`https://login.microsoftonline.com/${this.getTenantId()}`], // This is specified again to avoid a call to the following url everytime a token is retrieved even if a token is already cached https://login.microsoftonline.com/common/discovery/instance?api-version=1.1&authorization_endpoint=https://login.microsoftonline.com/c34279b7-82bd-453a-9788-a67de07fa821/oauth2/v2.0/authorize
                redirectUri: `${window.location.origin}/authentication`,
                postLogoutRedirectUri: `${window.location.origin}/logout`,
                navigateToLoginRequestUrl: true,
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false,
            }
        }
    }

}

export default AuthConfig;
import CurrencyDefaults from "./currency-defaults";

class Calculator {
    
    static calculateIOAmount(internalExpense, margin) {
        let expense = CurrencyDefaults.factory(internalExpense);
        let marginCurrency = CurrencyDefaults.factory(margin);
        let markup = marginCurrency.divide(CurrencyDefaults.factory(1).subtract(marginCurrency));
        let ioAmount = expense.multiply(markup).add(expense);
        return ioAmount;
    }
    
    static getMargin(ioLineItems) {
        if (!ioLineItems || !ioLineItems.length) {
            return 0;
        }
        return ioLineItems[0].margin;
    }
    
    /**
     * Calculates budget pacing information.
     * @param {number} startDate - The start date of the budget period as Unix timestamp
     * @param {number} endDate - The end date of the budget period as Unix timestamp
     * @param {number} today - The current date as Unix timestamp
     * @param {number} planned - The planned budget amount
     * @param {number} invoiced - The amount invoiced so far
     * @returns {Object} Budget pacing information
     */
    static getBudgetPacingInfo(startDate, endDate, today, planned, invoiced) {
        const msPerDay = 24 * 60 * 60 * 1000;
        const totalDays = Math.round((endDate - startDate) / msPerDay) + 1;
        const daysElapsed = Math.min(totalDays, Math.max(0, Math.floor((today - startDate) / (1000 * 60 * 60 * 24))));
        const daysRemaining = Math.max(0, totalDays - daysElapsed);
        
        const plannedCurrency = CurrencyDefaults.factory(planned);
        const invoicedCurrency = CurrencyDefaults.factory(invoiced);
        const balance = plannedCurrency.subtract(invoicedCurrency);

        const targetDailyBudget = daysRemaining > 0
          ? CurrencyDefaults.factory(balance).divide(daysRemaining)
          : CurrencyDefaults.factory(0);
        
        const averageDailyBudget = daysElapsed > 0
          ? invoicedCurrency.divide(daysElapsed)
          : CurrencyDefaults.factory(0);
        
        const idealSpendPerDay = CurrencyDefaults.factory(planned).divide(totalDays);
        const idealSpendToDate = CurrencyDefaults.factory(idealSpendPerDay).multiply(daysElapsed);
        const spendingAdjustmentPercentage = daysElapsed < 1
            ? CurrencyDefaults.factory(1)
            : balance.divide(idealSpendToDate);
        
        return {
            daysElapsed,
            daysRemaining,
            balance,
            targetDailyBudget,
            averageDailyBudget,
            idealSpendPerDay,
            idealSpendToDate,
            spendingAdjustmentPercentage
        };
    }
}

export default Calculator;
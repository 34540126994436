import React from 'react';

function Logout() {
    window.sessionStorage.removeItem('msal.interaction.status'); // If we keep this then MSAl might not redirect to login and require the window to be closed as a redirect loop safety.

    return (
        <div className="bg-gradient-primary logout" style={{height: '100%'}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-12 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-5 d-none d-lg-block bg-login-image"
                                        style={{backgroundImage: "url('/img/login.svg')", backgroundSize: '70%', backgroundRepeat: 'no-repeat' }}></div>
                                    <div className="col-lg-7">
                                        <div className="p-5" style={{minHeight: '23em'}}>
                                            <div className="mt-5 text-center">
                                                <h1 className="h4 text-gray-900 mb-4">Goodbye!</h1>
                                                <p className="mb-4">You have successfully logged out.</p>
                                            </div>
                                            <a className="btn btn-primary btn-user btn-block"
                                               href="/">Login</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="sticky-footer">
                    <div className="container my-auto">
                        <div className="copyright text-white text-center my-auto">
                            <p>Copyright © {new Date().getFullYear()} Roger West, LLC.</p>
                            <span className="small">Version 1.0</span>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default Logout;

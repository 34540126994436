import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import ApiClient from '../api-client';
import Template from './template';
import '../css/audit-trail.css';
import {toast} from "react-toastify";

function AuditTrail(props) {

    const [mgmtFeeData, setMgmtFeeData] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);

    const columns = [
        {
            name: 'Description',
            sortable: true,
            cell: (row, index, column) => {
                return <div>{row.description}</div>
            },
            grow: 3
        },
        {
            name: 'Date',
            selector: row => {
                const options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "2-digit",
                    timeZoneName: "short"
                };
                return new Date(row.updateDate).toLocaleString('en-US', options);
            },
            sortable: true
        },
        {
            name: 'User',
            selector: row => row.updatedBy
        }
    ];

    useEffect(() => {
        async function fetchData() {
            setTableLoading(true);
            try {
                let managementFees = await new ApiClient().fetch(`/AuditTrail`, 'GET');
                setMgmtFeeData(managementFees);
                setTableLoading(false);
            } catch (error) {
                toast.error(error, { autoClose: false});
            }
        }
        fetchData();
    }, [setMgmtFeeData]);

    return <Template selectedNavItem="media-expenses">
        <div className="container-fluid audit-trail-screen">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h4 className="m-0 font-weight-bold">Audit Trail</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-12">
                                    <DataTable
                                        className={"audit-trail-table"}
                                        columns={columns}
                                        data={mgmtFeeData}
                                        striped="true"
                                        highlightOnHover="true"
                                        progressPending={tableLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Template>;
}
export default AuditTrail;

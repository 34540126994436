import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import ApiClient from '../api-client';
import Template from './template';
import '../css/management-fee.css';
import CurrencyDefaults from "../currency-defaults";
import {toast} from "react-toastify";

function ManagementFees(props) {

    const [mgmtFeeData, setMgmtFeeData] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);

    const columns = [
        {
            name: 'Company',
            selector: row => row.company.name,
            sortable: true
        },
        {
            name: 'Percentage',
            selector: row => CurrencyDefaults.formatPercentage(row.percentage),
            sortable: true
        },
        {
            name: 'Minimum',
            selector: row => CurrencyDefaults.format(row.minimum)
        }
    ];

    useEffect(() => {
        async function fetchData() {
            setTableLoading(true);
            try {
                let managementFees = await new ApiClient().fetch(`/ManagementFee`, 'GET');
                setMgmtFeeData(managementFees);
                setTableLoading(false);
            } catch (error) {
                toast.error(error, { autoClose: false});
            }
        }
        fetchData();
    }, [setMgmtFeeData]);

    return <Template selectedNavItem="media-expenses">
        <div className="container-fluid management-fee-screen">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h4 className="m-0 font-weight-bold">Management Fees</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-12">
                                    <DataTable
                                        columns={columns}
                                        data={mgmtFeeData}
                                        striped="true"
                                        highlightOnHover="true"
                                        dense="true"
                                        progressPending={tableLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Template>;
}
export default ManagementFees;

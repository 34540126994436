import currency from 'currency.js';
/*
    Currency.js is required or simple arithmetic operations will have issues e.g. 2.51 + .01; // => 2.5199999999999996
 */
class CurrencyDefaults {
    static factory(amount) {
        return new currency(amount, {precision: 12});
    }
    
    static round(amount, precision) {
        let forcedRoundedAmount = new currency(amount, {precision: precision});
        return forcedRoundedAmount.value;
    }
    
    static format(currencyAmount) {
        let forcedRoundedAmount = this.round(currencyAmount, 2);
        const usdFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        });
        return usdFormatter.format(forcedRoundedAmount);
    }
    
    static formatFixed(currencyAmount) {
        let forcedRoundedAmount = this.round(currencyAmount, 2);
        const usdFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return usdFormatter.format(forcedRoundedAmount);
    }
    
    static formatPercentage(decimalPercentage) {
        return new Intl.NumberFormat('en-IN', {
            style: 'percent',
            maximumFractionDigits: 20 }
        ).format(decimalPercentage);
    }

    static trim(amount) {
        return new Intl.NumberFormat('en-IN', {
            maximumFractionDigits: 20 }
        ).format(amount);
    }
}

export default CurrencyDefaults;
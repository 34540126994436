import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "react-tooltip";

function ActualExpenseModal(props) {

    return <div>
        {
            props.model.visible &&
            <div>
                <div className="modal fade show" id="logoutModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     style={{display: 'block'}} aria-modal="true" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Media Expense
                                </h5>
                                <div style={{cursor: 'default'}}>
                                        <span
                                            data-tooltip-id="history-tooltip"
                                            data-tooltip-place="bottom"
                                        >
                                            History
                                            <FontAwesomeIcon icon={faCircleInfo} style={{
                                                marginRight: '15px',
                                                marginLeft: '3px',
                                                fontSize: '.8rem'
                                            }}/>
                                        </span>
                                    <Tooltip id="history-tooltip">
                                        <div>Last
                                            Updated: {props.model.campaignGroupMonth && props.model.campaignGroupMonth.actualExpense ? new Date(Date.parse(props.model.campaignGroupMonth.actualExpense.updateDateUtc)).toLocaleString() : ''}</div>
                                        <div>Updated
                                            By: {props.model.campaignGroupMonth && props.model.campaignGroupMonth.actualExpense ? props.model.campaignGroupMonth.actualExpense.updatedBy : ''}</div>
                                    </Tooltip>
                                    <button
                                        onClick={props.cancel}
                                        className="close" type="button" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div>
                                    {
                                        props.model.errorMessage &&
                                        <div className="col-xl-12">
                                            <div className={`alert alert-dismissible alert-danger`} role="alert">
                                                {props.model.errorMessage}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div>
                                    <strong>Month</strong>
                                    <div>{new Date(props.model.year, props.model.month - 1).toLocaleString('default', {month: 'long'})}</div>
                                </div>
                                <div>
                                    <strong>Company</strong>
                                    <div>{props.model.company.name}</div>
                                </div>
                                <div>
                                    <strong>Campaign</strong>
                                    <div>{props.model.campaign.name}</div>
                                </div>
                                <div>
                                    <strong>Platform</strong>
                                    <div>{props.model.platform.name}</div>
                                </div>
                                <div>
                                    <strong>Invoiced&nbsp;</strong>
                                    <input type="text"
                                           className="form-control"
                                           value={props.model.inputInvoiceAmount || ''}
                                           onChange={e => props.updateModel({
                                               ...props.model,
                                               inputInvoiceAmount: e.target.value
                                           })}
                                    />
                                </div>
                                <div>
                                    <strong>Invoice Number&nbsp;</strong>
                                    <input type="text"
                                           className="form-control"
                                           value={props.model.inputInvoiceNumber || ''}
                                           onChange={e => props.updateModel({
                                               ...props.model,
                                               inputInvoiceNumber: e.target.value
                                           })}
                                    />
                                </div>
                                <div>
                                    <strong>Margin&nbsp;</strong>
                                    {props.model.marginWarningText && <p className="text-warning">{props.model.marginWarningText}</p>}
                                    <input type="text"
                                           className="form-control"
                                           value={props.model.margin || ''}
                                           onChange={e => props.updateModel({
                                               ...props.model,
                                               margin: e.target.value
                                           })}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button onClick={props.cancel} className="btn btn-secondary" type="button"
                                        data-dismiss="modal">Cancel
                                </button>
                                <button className="btn btn-primary" onClick={props.save}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
            </div>
        }
    </div>
}
export default ActualExpenseModal;
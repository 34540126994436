import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import CurrencyDefaults from '../currency-defaults';
import ApiClient from '../api-client';
import Template from './template';
import '../css/floating-labels.css';
import '../css/margin.css';
import 'react-tooltip/dist/react-tooltip.css'
import MarginModal from '../components/margin-modal';
import {toast} from "react-toastify";

function Margins(props) {

    const [marginData, setMarginData] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [marginModalModel, setMarginModalModel] = useState({visible: false});

    const columns = [
        {
            name: 'Company',
            selector: row => getCompanyText(row),
            sortable: true
        },
        {
            name: 'Platform',
            selector: row => getPlatformText(row),
            sortable: true
        },
        {
            name: 'Margin',
            selector: row => CurrencyDefaults.formatPercentage(CurrencyDefaults.factory(row.margin))
        },
        {
            name: '',
            cell: (row, index, column) => {
                return <span
                    onClick={() => {
                        setMarginModalModel({
                            visible: true,
                            data: row,
                            marginText: CurrencyDefaults.trim(CurrencyDefaults.factory(row.margin).multiply(100).toString())
                        });
                    }}
                    className="clickable">Edit</span>
            }
        },
        {
            name: '',
            cell: (row, index, column) => {
                return <span onClick={() => deleteMargin(row) } className="clickable">Delete</span>
            }
        }
    ];

    useEffect(() => {
        async function fetchData() {
            try {
                let companiesPromise = new ApiClient().fetch(`/Campaign/companies`, 'GET', null, false);
                let platformsPromise = new ApiClient().fetch(`/Campaign/platforms`, 'GET', null, false);
                let marginsPromise = refreshMargins();

                let companies = await companiesPromise;
                let platforms = await platformsPromise
                await marginsPromise;

                setCompanies(companies);
                setPlatforms(platforms);
            } catch (error) {
                toast.error(error, { autoClose: false});
            }
        }
        fetchData();
    }, [setMarginData]);

    function getMarginDescription(margin) {
        return getCompanyText(margin) + '/' + getPlatformText(margin)
    }

    function getCompanyText(margin) {
        return margin.company ? margin.company.name : 'Any'
    }

    function getPlatformText(margin) {
        if (!margin.platform) {
            return 'Any';
        }
        let platformName = (margin.platform.description || '').toLowerCase() === (margin.platform.name || '').toLowerCase()
            ? margin.platform.description
            : `${margin.platform.description} - ${margin.platform.name}`;
        return platformName;
    }

    async function refreshMargins() {
        setTableLoading(true);
        try {
            let margins = await new ApiClient().fetch(`/Margin`, 'GET', null, false);
            setMarginData(margins);
            setTableLoading(false);
        } catch (error) {
            toast.error(error, { autoClose: false});
        }
    }

    async function addOrUpdateMargin(model) {
        let validationText = getMarginValidation(model);
        if (validationText.length > 0) {
            setMarginModalModel({...marginModalModel, errorMessage: validationText })
            return;
        }
        model.margin = CurrencyDefaults.trim(CurrencyDefaults.factory(marginModalModel.marginText).divide(100).toString());
        try {
            let newOrUpdatedModel = await new ApiClient().fetch('/Margin', 'POST', model, {loadingMessage: 'Saving', successMessage: 'Saved'});
            let marginDataCopy = model.id > 0
                ? marginData.filter(x => x.id !== model.id)
                : [...marginData];
            marginDataCopy.push(newOrUpdatedModel);
            setMarginData(marginDataCopy);
            setMarginModalModel({...marginModalModel, visible: false })
            await refreshMargins();
        } catch (error) {
            // Api client handles messaging.
        }
    }

    async function deleteMargin(margin) {
        try {
            await new ApiClient().fetch(`/Margin/${margin.id}`, 'DELETE', null, {loadingMessage: 'Deleting', successMessage: 'Deleted'});
            setMarginData(marginData.filter(x => x.id !== margin.id));
        } catch (error) {
            // Api client handles messaging.
        }
    }

    function getMarginValidation(marginModel) {
        if (!marginModalModel.marginText || marginModalModel.marginText.length === 0) {
            return 'Margin is required';
        }
        if (isNaN(marginModalModel.marginText)) {
            return 'Margin must be numeric';
        }
        let marginMatch = marginData.find(x =>
            (x.company ? x.company.id : 0) === (marginModel.company ? marginModel.company.id : 0) &&
            (x.platform ? x.platform.id : 0) === (marginModel.platform ? marginModel.platform.id : 0) &&
            marginModel.id !== x.id
        )
        if (marginMatch) {
            return `Margin ${getMarginDescription(marginMatch)} already exists`;
        }
        let parsedMargin = parseFloat(marginModalModel.marginText);
        if (parsedMargin > 100 || parsedMargin < 0) {
            return `Margin must be between 0 and 100`;
        }
        return '';
    }

    function showAddNewMargin() {
        setMarginModalModel({
            ...marginModalModel,
            visible: true,
            data: {},
            marginText: ''
        });
    }

    return <Template selectedNavItem="media-expenses">
        <div className="container-fluid margins-screen">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h4 className="m-0 font-weight-bold">Margins</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-12">
                                    <button onClick={showAddNewMargin} className="btn btn-primary add-new-margin btn-header">Add New Margin</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <DataTable
                                        columns={columns}
                                        data={marginData}
                                        striped="true"
                                        highlightOnHover="true"
                                        dense="true"
                                        progressPending={tableLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            <MarginModal
                model={marginModalModel}
                companies={companies}
                platforms={platforms}
                updateModel={(updated) => setMarginModalModel(updated) }
                cancel={() => setMarginModalModel({visible: false, data: null, marginText: ''})}
                save={() => {
                    addOrUpdateMargin(marginModalModel.data)
                }}
            />
        }
    </Template>;
}
export default Margins;

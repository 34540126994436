import React from 'react';

function Authentication() {
    // This page might flash during authentication, so it should stay completely blank for a clean looking between redirects.
    return (
        <div>
        </div>
    );
}

export default Authentication;
